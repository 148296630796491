import React, { Fragment } from "react";
import { message, Space } from "antd";
import FileLoad from "../components/FileLoad";
import Wrapper from "../components/Wrapper";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

export default function Files() {
  return (
    <Space size="large" direction="vertical">
      <h3>Files</h3>
      <div>
        <p>
          This is a file manager for uploading files from your device to the
          server, as well as their deletion on the server.
        </p>
        <p>
          To upload the photo, click on the "<b>upload file</b>" button at the
          bottom of the page. In the dialog box that appears, select the file to
          upload on your device.
        </p>
        <p>
          To delete the file, you need to bring the cursor on it and click on
          the right mouse button. Then you need to click on the pop-up menu, on
          which it is written "<b>delete</b>". It is recommended to delete
          unnecessary files.
        </p>
        <p>
          When you click on a file, a link to it is copied, which you can paste
          when <b>adding an image in a text editor.</b>
        </p>
      </div>
      <Wrapper title="File manager">
        <FileLoad
          photo={(arg) => {
            // console.log(REACT_APP_API_PORT + arg);
            navigator.clipboard
              .writeText(REACT_APP_API_PORT + "dir/" + arg)
              .then(() => {
                alert("Copied to clipboard");
              })
              .catch((err) => console.log(err));
          }}
        />
      </Wrapper>
    </Space>
  );
}
