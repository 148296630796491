import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, message, Input, Space, Table } from "antd";
import Wrapper from "../components/Wrapper";
import moment from "moment";
import { observer } from "mobx-react";
import lang from "../store/lang";
import auth from "../store/auth";
import { DeleteOutlined } from "@ant-design/icons";
import DeleteModal from "../components/DeleteModal";
import axios from "axios";
import ProductForm from "../components/ProductForm";

const dateFormat = "DD.MM.YYYY";
const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const Products = observer(() => {
  const [data, setData] = useState(null);
  const [newForm, showNewForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    _id: null,
    visible: false,
  });
  useEffect(() => {
    axios.get(`${REACT_APP_API_PORT}api/products`, {
      headers: {
        "Content-Type": "application/json",
        "lang": lang.value,
      },
    }).then((res) => {
      setData(res.data);
    });
  }, [lang.value]);
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, { _id }) => (
        <Link to={`/products/${_id}`}>
          <h4>{text}</h4>
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => <i>{moment(date).format(dateFormat)}</i>,
    },
    {
      key: "_id",
      dataIndex: "_id",
      render: (_id) => (
        <DeleteOutlined
          className="_remove"
          onClick={() => {
            setDeleteModal({ _id: _id, visible: true });
          }}
        />
      ),
    },
  ];
  return (
    <Fragment>
      <Space size="large" direction="vertical">
        <h3>Products</h3>
        {newForm ? (
          <Wrapper title="Create new document">
            <ProductForm
              reset="reset"
              close={() => showNewForm(false)}
              callBack={(args) => {
                axios
                  .post(
                    `${REACT_APP_API_PORT}api/products`,
                    { ...args, lang: lang.value },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        "x-auth-token": auth.token,
                      },
                    }
                  )
                  .then((res) => {
                    message.success("Document created sucessfully");
                    showNewForm(false);
                    setData([...data, res.data]);
                  })
                  .catch((err) => {
                    console.log(err);
                    // logout
                    // auth.logout();
                  });
              }}
            />
          </Wrapper>
        ) : (
          <Button
            type="primary"
            className="_xbutton"
            onClick={() => showNewForm(true)}
          >
            Create new document
          </Button>
        )}
        {data && (
          <Wrapper title="Documents list">
            <Table dataSource={data} rowKey="_id" columns={columns} />
          </Wrapper>
        )}
      </Space>
      <DeleteModal
        ok={() => {
          axios
            .delete(`${REACT_APP_API_PORT}api/products/${deleteModal._id}`, {
              headers: {
                "Content-Type": "application/json",
                "x-auth-token": auth.token,
              },
            })
            .then((res) => {
              console.log(res);
              setData(data.filter((x) => x._id != deleteModal._id));
              setDeleteModal({ _id: null, visible: false });
              message.info("Document deleted successfully");
            })
            .catch((err) => {
              console.log(err);
              // logout
              // auth.logout();
            });
        }}
        visible={deleteModal.visible}
        cancel={() => setDeleteModal({ _id: null, visible: false })}
      />
    </Fragment>
  );
});

export default Products;
