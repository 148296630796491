import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TopNav from "./components/TopNav";
import Sidebar from "./components/Sidebar";
import Users from "./routes/Users";
import User from "./routes/User";
import Files from "./routes/Files";
import Dashboard from "./routes/Dashboard";
import Items from "./routes/Items";
import Item from "./routes/Item";
import Product from "./routes/Product";
import Products from "./routes/Products";
import Service from "./routes/Service";
import Services from "./routes/Services";
import Orders from "./routes/Orders";
import Subscribers from "./routes/Subscribers";

const Private = () => {
  return (
    <BrowserRouter>
      <Sidebar />
      <div className="_main_panel">
        <TopNav />
        <div className="_content">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="files" element={<Files />} />
            <Route path="users/:id" element={<User />} />
            <Route path="users" element={<Users />} />
            <Route path="items/:id" element={<Item />} />
            <Route path="items" element={<Items />} />
            <Route path="products/:id" element={<Product />} />
            <Route path="products" element={<Products />} />
            <Route path="services/:id" element={<Service />} />
            <Route path="services" element={<Services />} />
            <Route path="orders" element={<Orders />} />
            <Route path="subscribers" element={<Subscribers />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Private;
