import React from "react";
import MenuItems from "./MenuItems";

const MobMenu = ({ trigger }) => {
  return (
    <div id="Mobile_Wrapper" className={trigger ? "_mobile_open" : ""}>
      <div className="_sidebar">
        <MenuItems />
      </div>
    </div>
  );
};

export default MobMenu;
