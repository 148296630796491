import React from "react";
import { Button, Modal } from "antd";

export default function DeleteModal({ cancel, ok, visible }) {
  return (
    <Modal
      title={<h4>Are you sure to delete the document?</h4>}
      centered
      visible={visible}
      onCancel={() => cancel()}
      footer={false}
    >
      <Button
        type="primary"
        htmlType="submit"
        className="_xbutton"
        onClick={() => {
          ok();
        }}
      >
        OK
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        className="_xbutton _xbutton_dark"
        style={{ marginLeft: "15px" }}
        onClick={() => cancel()}
      >
        Cancel
      </Button>
    </Modal>
  );
}
