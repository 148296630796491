import React, { Fragment, useState } from "react";
import { Drawer, Image } from "antd";
import { PictureOutlined } from "@ant-design/icons";
import FileLoad from "./FileLoad";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

export default function ImageModal({ photo, _photo }) {
  const [state, setState] = useState({ photo: photo, visible: false });
  return (
    <Fragment>
      <div
        className="_icon_xl"
        onClick={() => setState({ ...state, visible: true })}
      >
        {state.photo ? (
          <Image
            width={200}
            src={REACT_APP_API_PORT + "dir/" + state.photo}
            preview={false}
          />
        ) : (
          <PictureOutlined />
        )}
      </div>
      <Drawer
        title="Select or upload photo"
        placement="right"
        onClose={() => setState({ ...state, visible: false })}
        visible={state.visible}
        width="85%"
      >
        {photo && (
          <Image
            width={200}
            src={REACT_APP_API_PORT + "dir/" + photo}
            style={{ marginBottom: "25px" }}
            preview={false}
          />
        )}
        <FileLoad
          photo={(arg) => {
            setState({ ...state, photo: arg });
            _photo(arg);
          }}
        />
      </Drawer>
    </Fragment>
  );
}
