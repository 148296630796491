import { makeAutoObservable, runInAction } from "mobx";

class lang {
  value = "ru";

  constructor() {
    makeAutoObservable(this);
  }

  setLang(arg) {
    this.value = arg;
  }
}

export default new lang();
