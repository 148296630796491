import React, { Fragment, useState } from "react";
import { Avatar, Menu, Layout } from "antd";
import { IeOutlined, LogoutOutlined } from "@ant-design/icons";
import Lang from "./Lang";
import { observer } from "mobx-react";
import auth from "../store/auth";
import MobMenu from "./MobMenu";

const { Header } = Layout;

const TopNav = () => {
  const [trigger, setTrigger] = useState(false);
  const items = [
    {
      label: <span style={{ cursor: "initial" }}>User name</span>,
      icon: (
        <Avatar
          style={{ cursor: "initial" }}
          size="large"
          src={auth.avatar}
          onClick={(_) => {
            console.log(auth.avatar);
            console.log(auth.token);
          }}
        />
      ),
    },
    { label: <Lang /> },
    {
      label: <span onClick={() => auth.logout()}>Exit</span>,
      icon: <LogoutOutlined onClick={() => auth.logout()} />,
    },
  ];
  return (
    <Fragment>
      <Header>
        <div
          id="Mobile_Trigger"
          onClick={() => setTrigger(!trigger)}
          className={trigger ? "_open" : ""}
        >
          <div className="_trigger_content">
            <span class="_trigger-top"></span>
            <span class="_trigger-middle"></span>
            <span class="_trigger-bottom"></span>
          </div>
        </div>
        <Menu mode="horizontal" selectable={false} id="Top_Nav" items={items} />
      </Header>
      <MobMenu trigger={trigger} />
    </Fragment>
  );
};

export default TopNav;
