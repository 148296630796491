import React from "react";
import MenuItems from "./MenuItems";

const Sidebar = () => {
  return (
    <div id="Sidebar_Wrapper" className="_sidebar_open">
      <div className="_sidebar">
        <MenuItems />
      </div>
    </div>
  );
};

export default Sidebar;
