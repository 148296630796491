import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { observer } from "mobx-react";
import auth from "../store/auth";
import UserForm from "../components/UserForm";
import { message } from "antd";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const User = observer(() => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/users/${id}`)
      .then((res) => setData(res.data));
  }, []);
  return (
    <Fragment>
      <h3>Main page</h3>
      <p>You can edit user email and reset password</p>
      {data && (
        <UserForm
          data={data}
          callBack={(args) => {
            axios
              .patch(`${REACT_APP_API_PORT}api/users/${id}`, args, {
                headers: {
                  "Content-Type": "application/json",
                  "x-auth-token": auth.token,
                },
              })
              .then((res) => {
                console.log(res.data);
                message.success("Password updated suscessfully");
              })
              .catch((err) => {
                console.log(err);
                // logout
                auth.logout();
              });
          }}
        />
      )}
    </Fragment>
  );
});

export default User;
