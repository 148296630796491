import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, message, Space, Table } from "antd";
import axios from "axios";
import Wrapper from "../components/Wrapper";
import { observer } from "mobx-react";
import auth from "../store/auth";
import { DeleteOutlined } from "@ant-design/icons";
import DeleteModal from "../components/DeleteModal";
import UserForm from "../components/UserForm";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

export const Users = observer(() => {
  const [newForm, showNewForm] = useState(false);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ _id: null, visible: false });
  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/users`)
      .then((res) => setData(res.data));
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, { _id }) => (
        <Link to={`/users/${_id}`}>
          <h4>{text}</h4>
        </Link>
      ),
    },
    {
      key: "_id",
      dataIndex: "_id",
      render: (_id) => (
        <DeleteOutlined
          className="_remove"
          onClick={() => {
            setDeleteModal({ _id: _id, visible: true });
          }}
        />
      ),
    },
  ];

  return (
    <Space size="large" direction="vertical">
      <h3>Users list</h3>
      <p>
        Here you can add a new user, as well as edit and delete. To change the
        password, you can click on the "reset password" button, after which the
        system will offer the generated password. You can type your own password
        by deleting the generated one. The password is case sensitive.
      </p>
      {newForm ? (
        <Wrapper title="Create new user">
          <UserForm
            reset="reset"
            close={() => showNewForm(false)}
            callBack={(args) => {
              axios
                .post(`${REACT_APP_API_PORT}api/users`, args, {
                  headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": auth.token,
                  },
                })
                .then((res) => {
                  message.success("User created sucessfully");
                  showNewForm(false);
                  setData([...data, res.data]);
                })
                .catch((err) => {
                  console.log(err);
                  // logout
                  auth.logout();
                });
            }}
          />
        </Wrapper>
      ) : (
        <Button
          type="primary"
          className="_xbutton"
          onClick={() => showNewForm(true)}
        >
          Create new user
        </Button>
      )}
      {data && (
        <Wrapper title="Users list">
          <Table dataSource={data} rowKey="_id" columns={columns} />
        </Wrapper>
      )}
      <DeleteModal
        ok={() => {
          axios
            .delete(`${REACT_APP_API_PORT}api/users/${deleteModal._id}`, {
              headers: {
                "Content-Type": "application/json",
                "x-auth-token": auth.token,
              },
            })
            .then((res) => {
              setData(data.filter((x) => x._id != res.data._id));
              setDeleteModal({ _id: null, visible: false });
              message.info("User deleted successfully");
            })
            .catch((err) => {
              console.log(err);
              // logout
              auth.logout();
            });
        }}
        visible={deleteModal.visible}
        cancel={() => setDeleteModal({ _id: null, visible: false })}
      />
    </Space>
  );
});

export default Users;
