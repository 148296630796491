import React, { Fragment, useRef, useState } from "react";
import { Button, Card } from "antd";

export default function Wrapper({ children, title }) {
  return (
    <Card className="_wrapper" title={title}>
      {children}
    </Card>
  );
}
