import React, { useState, Fragment, useEffect } from "react";
import { Button, Col, message, Row, Tooltip, Upload } from "antd";
import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";
import auth from "../store/auth";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const FileLoad = observer(({ photo }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/files`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);

  const load = {
    action: `${REACT_APP_API_PORT}api/files`,
    headers: { "x-auth-token": auth.token },
    onChange({ file }) {
      if (file.status === "done") {
        message.success("File uploaded successfully");
        const { response } = file;
        setData([...data, response.name]);
      }
    },
  };
  return (
    <Fragment>
      <Row gutter={15} className="_files">
        {data &&
          data.map((file) => (
            <Col lg={{ span: 3 }} sm={{ span: 6 }} span={12} key={uuidv4()}>
              <Tooltip
                trigger="contextMenu"
                title={() => (
                  <div
                    className="_remove_file"
                    onClick={(_) => {
                      axios
                        .delete(`${REACT_APP_API_PORT}api/files`, {
                          headers: {
                            "Content-Type": "application/json",
                            "x-auth-token": auth.token,
                            filename: file,
                          },
                        })
                        .then((res) => setData(data.filter((x) => x != file)))
                        .catch((err) => {
                          console.log(err);
                          // logout
                          auth.logout();
                        });
                    }}
                  >
                    <span>remove</span>
                    <CloseCircleOutlined />
                  </div>
                )}
              >
                <img
                  id={file}
                  src={REACT_APP_API_PORT + "dir/" + file}
                  alt={file}
                  style={{ maxWidth: "100%", cursor: "pointer" }}
                  onClick={() => {
                    if (photo) photo(file);
                  }}
                />
              </Tooltip>
              <p>{file}</p>
            </Col>
          ))}
      </Row>
      <Upload id="file" {...load}>
        <Button key="load" type="primary" className="_xbutton">
          <UploadOutlined />
          Upload file
        </Button>
      </Upload>
    </Fragment>
  );
});

export default FileLoad;
