import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { message, Space, Table } from "antd";
import Wrapper from "../components/Wrapper";
import { observer } from "mobx-react";
import auth from "../store/auth";
import { DeleteOutlined } from "@ant-design/icons";
import DeleteModal from "../components/DeleteModal";
import axios from "axios";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const Orders = observer(() => {
  const [data, setData] = useState(null);
  const [deleteModal, setDeleteModal] = useState({
    _id: null,
    visible: false,
  });
  useEffect(() => {
    axios.get(`${REACT_APP_API_PORT}api/orders`).then((res) => {
      setData(res.data);
    });
  }, []);
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, { _id }) => (
        <Link to={`/orders/${_id}`}>
          <h4>{text}</h4>
        </Link>
      ),
    },
    {
      key: "_id",
      dataIndex: "_id",
      render: (_id) => (
        <DeleteOutlined
          className="_remove"
          onClick={() => {
            setDeleteModal({ _id: _id, visible: true });
          }}
        />
      ),
    },
  ];
  return (
    <Fragment>
      <Space size="large" direction="vertical">
        <h3>Orders list</h3>
        {data && (
          <Wrapper title="Documents list">
            <Table dataSource={data} rowKey="_id" columns={columns} />
          </Wrapper>
        )}
      </Space>
      <DeleteModal
        ok={() => {
          axios
            .delete(`${REACT_APP_API_PORT}api/orders/${deleteModal._id}`, {
              headers: {
                "Content-Type": "application/json",
                "x-auth-token": auth.token,
              },
            })
            .then((res) => {
              console.log(res);
              setData(data.filter((x) => x._id != deleteModal._id));
              setDeleteModal({ _id: null, visible: false });
              message.info("Document deleted successfully");
            })
            .catch((err) => {
              console.log(err);
              // logout
              // auth.logout();
            });
        }}
        visible={deleteModal.visible}
        cancel={() => setDeleteModal({ _id: null, visible: false })}
      />
    </Fragment>
  );
});

export default Orders;
