import React from "react";
import { Button, Form, Input } from "antd";
import { observer } from "mobx-react";
import auth from "./store/auth";
import axios from "axios";

const Login = observer(() => {
  return (
    <div style={{ padding: "50px" }}>
      <Form
        layout="vertical"
        onFinish={(values) => {
          axios
            .post(`${process.env.REACT_APP_API_PORT}api/auth`, values)
            .then((res) => {
              auth.setAuth(res.data.avatar, res.data.token);
            })
            .catch((err) => {
              console.log(err);
              alert("Incorrect login or password");
            });
        }}
      >
        <Form.Item
          name="email"
          label="Login (email)"
          rules={[
            {
              required: true,
              message: "Please enter user email!",
            },
          ]}
        >
          <Input placeholder="Enter user email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="_xbutton">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});

export default Login;
