import React, { Fragment, useRef, useState } from "react";
import { Button, Form, Input, DatePicker, Radio, message } from "antd";
import moment from "moment";
import ImageModal from "./ImageModal";
import JoditEditor from "jodit-react";

const dateFormat = "DD.MM.YYYY";
const { TextArea } = Input;

export default function ItemForm({ callBack, close, data, reset }) {
  const [form] = Form.useForm();
  const [photo, setPhoto] = useState(data ? data.img : null);
  const editor = useRef(null);
  const [content, setContent] = useState(data ? data.text : "");
  const config = {
    readonly: false,
  };
  return (
    <Fragment>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...data,
          date: moment(data ? new Date(data.date) : new Date(), dateFormat),
        }}
        onFinish={(values) => {
          if (!content.length || !photo) {
            message.error("Please fill in all inputs!");
          } else
            callBack({
              ...values,
              date: new Date(values.date).toISOString(),
              img: photo,
              text: content,
            });
          // reset && form.resetFields();
        }}
      >
        <Form.Item name="title" label="Title">
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item name="description" label="Excerpt">
          <TextArea
            autoSize
            style={{ minHeight: 100 }}
            placeholder="Enter description"
          />
        </Form.Item>
        <Form.Item label="Image">
          <ImageModal photo={photo} _photo={(arg) => setPhoto(arg)} />
        </Form.Item>
        <Form.Item label="Publishing date" name="date">
          <DatePicker
            size="medium"
            format={dateFormat}
            placeholder="Publishing date"
          />
        </Form.Item>
        <Form.Item label="Text">
          <div className="editor123">
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => setContent(newContent)}
            />
          </div>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="_xbutton">
            Submit
          </Button>
          {close && (
            <Button
              type="primary"
              className="_xbutton _xbutton_dark"
              style={{ marginLeft: "15px" }}
              onClick={() => close()}
            >
              Close
            </Button>
          )}
        </Form.Item>
      </Form>
    </Fragment>
  );
}
