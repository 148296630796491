import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, message } from "antd";
import { observer } from "mobx-react";
import auth from "../store/auth";
import Wrapper from "../components/Wrapper";
import ImageModal from "../components/ImageModal";
import axios from "axios";

const { TextArea } = Input;

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;
const REACT_APP_MAIN_DOC = process.env.REACT_APP_MAIN_DOC;

const Dashboard = observer(() => {
  const [data, setData] = useState(null);
  const [images, setImages] = useState({});
  useEffect(() => {
    axios.get(`${REACT_APP_API_PORT}api/main`).then((res) => {
      setData(res.data[0]);
      setImages({
        logo: res.data[0].logo,
        mainbg: res.data[0].mainbg,
        productsbg: res.data[0].productsbg,
        formbg: res.data[0].formbg,
        subbg: res.data[0].subbg,
      });
    });
  }, []);

  return (
    <div className="_settings">
      <h3>Dashboard (common settings)</h3>
      <p>
        Here you can change the basic information that is displayed at the main
        page of the website, as well as in the "Contacts" section.
      </p>
      {data && (
        <Wrapper title="Main data">
          <Form
            labelCol={{ xl: { span: 5 }, lg: { span: 10 }, span: 8 }}
            wrapperCol={{ xl: { span: 10 }, lg: { span: 14 }, span: 16 }}
            initialValues={data}
            onFinish={(values) => {
              // console.log({ ...values, ...images })
              axios
                .patch(
                  `${REACT_APP_API_PORT}api/main/${REACT_APP_MAIN_DOC}`,
                  { ...values, ...images },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      "x-auth-token": auth.token,
                    },
                  }
                )
                .then((res) => {
                  console.log(res.data);
                  message.success("Data updated suscessfully");
                })
                .catch((err) => {
                  console.log(err);
                  // logout
                  // auth.logout();
                });
            }}
          >
            <Form.Item name="title" label="Website title">
              <Input placeholder="Website title" />
            </Form.Item>
            <Form.Item name="titleEn" label="Website title (english)">
              <Input placeholder="Website title english" />
            </Form.Item>
            <Form.Item name="phone" label="Contact phone">
              <Input placeholder="Contact phone" />
            </Form.Item>
            <Form.Item name="email" label="Contact email">
              <Input placeholder="Contact email" />
            </Form.Item>
            <Form.Item name="facebook" label="Facebook link">
              <Input placeholder="Facebook link" />
            </Form.Item>
            <Form.Item name="instagram" label="Instagram link">
              <Input placeholder="Instagram link" />
            </Form.Item>
            <Form.Item name="twitter" label="Twitter link">
              <Input placeholder="Twitter link" />
            </Form.Item>
            <Form.Item name="vk" label="Vk link">
              <Input placeholder="Vk link" />
            </Form.Item>
            <Form.Item name="github" label="Github link">
              <Input placeholder="Github link" />
            </Form.Item>
            <Form.Item name="linkedin" label="Linkedin link">
              <Input placeholder="Linkedin link" />
            </Form.Item>
            <Form.Item name="address" label="Address">
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item label="Logo image">
              <ImageModal
                photo={images.logo}
                _photo={(arg) => setImages({ ...images, logo: arg })}
              />
            </Form.Item>
            <Form.Item name="description" label="Main page description">
              <TextArea autoSize />
            </Form.Item>
            <Form.Item
              name="descriptionEn"
              label="Main page description in english"
            >
              <TextArea autoSize />
            </Form.Item>
            <Form.Item label="Main background">
              <ImageModal
                photo={images.mainbg}
                _photo={(arg) => setImages({ ...images, mainbg: arg })}
              />
            </Form.Item>
            <Form.Item label="Portfolio background image">
              <ImageModal
                photo={images.productsbg}
                _photo={(arg) => setImages({ ...images, productsbg: arg })}
              />
            </Form.Item>
            <Form.Item label="Form background image">
              <ImageModal
                photo={images.formbg}
                _photo={(arg) => setImages({ ...images, formbg: arg })}
              />
            </Form.Item>
            <Form.Item label="Subheader background image">
              <ImageModal
                photo={images.subbg}
                _photo={(arg) => setImages({ ...images, subbg: arg })}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="_xbutton">
                Save changes
              </Button>
            </Form.Item>
          </Form>
        </Wrapper>
      )}
    </div>
  );
});

export default Dashboard;
