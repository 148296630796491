import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import {
  BarsOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  FolderOutlined,
  HomeOutlined,
  MailOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
} from "@ant-design/icons";

export default function MenuItems() {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const items = [
    {
      label: <Link to="/items">Different pages</Link>,
      icon: <FileTextOutlined />,
    },
    {
      label: <Link to="/products">Products</Link>,
      icon: <CreditCardOutlined />,
    },
    {
      label: <Link to="/services">Services</Link>,
      icon: <BarsOutlined />,
    },
    {
      label: <Link to="/orders">Orders</Link>,
      icon: <ShoppingCartOutlined />,
    },
    {
      label: <Link to="/files">File manager</Link>,
      icon: <FolderOutlined />,
    },
    {
      label: <Link to="/subscribers">Subscribers</Link>,
      icon: <MailOutlined />,
    },
    {
      label: <Link to="/users">Users</Link>,
      icon: <TeamOutlined />,
    },
  ];
  return (
    <Fragment>
      <div className="_logo">
        <Link to="/">
          <HomeOutlined />
          <p>Dashboard</p>
        </Link>
      </div>
      <Menu
        mode="inline"
        items={items}
        selectedKeys={selectedKeys}
        onSelect={(e) => {
          setSelectedKeys([e.key]);
        }}
      />
    </Fragment>
  );
}
