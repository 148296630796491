import React, { Fragment, useEffect, useState } from "react";
import { message, Space, Table } from "antd";
import axios from "axios";
import Wrapper from "../components/Wrapper";
import { observer } from "mobx-react";
import auth from "../store/auth";
import { DeleteOutlined } from "@ant-design/icons";
import DeleteModal from "../components/DeleteModal";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const Subscribers = observer(() => {
  const [deleteModal, setDeleteModal] = useState({ _id: null, visible: false });
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/mail`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <h4>{text}</h4>,
    },
    {
      key: "_id",
      dataIndex: "_id",
      render: (_id) => (
        <DeleteOutlined
          className="_remove"
          onClick={() => {
            setDeleteModal({ _id: _id, visible: true });
          }}
        />
      ),
    },
  ];

  return (
    <Space size="large" direction="vertical">
      <h3>Subscribers list</h3>
      <p>List of subscribers who were added in the "newsletter" section.</p>
      {data && (
        <Wrapper title="Subscribers list">
          <Table dataSource={data} rowKey="_id" columns={columns} />
        </Wrapper>
      )}
      <DeleteModal
        ok={() => {
          axios
            .delete(`${REACT_APP_API_PORT}api/mail/${deleteModal._id}`, {
              headers: {
                "Content-Type": "application/json",
                "x-auth-token": auth.token,
              },
            })
            .then((res) => {
              setData(data.filter((x) => x._id != res.data._id));
              setDeleteModal({ _id: null, visible: false });
              message.info("Document deleted successfully");
            })
            .catch((err) => {
              console.log(err);
              // logout
              auth.logout();
            });
        }}
        visible={deleteModal.visible}
        cancel={() => setDeleteModal({ _id: null, visible: false })}
      />
    </Space>
  );
});

export default Subscribers;
