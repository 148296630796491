import React, { Fragment, useState } from "react";
import { Avatar, Button, Divider, Form, Input } from "antd";

function UserForm({ callBack, close, data, reset }) {
  const [form] = Form.useForm();
  const [resetPassword, setResetPassword] = useState(false);
  const [password, setPassword] = useState(null);

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={data}
      onFinish={(values) => {
        let args = { ...values };
        if (password) args.password = password;
        callBack(args);
        reset && form.resetFields();
      }}
    >
      {data && (
        <Form.Item>
          <Avatar size={100} src={data.avatar} />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Please enter user name" }]}
      >
        <Input placeholder="Please enter user name" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: "Please enter user email" }]}
      >
        <Input placeholder="Please enter user email" />
      </Form.Item>
      {data ? (
        <Fragment>
          {resetPassword ? (
            <Fragment>
              <Form.Item label="New pasword">
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
            </Fragment>
          ) : (
            <Form.Item label="Reset pasword">
              <Button
                type="primary"
                className="_xbutton"
                onClick={() => {
                  let chars =
                    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                  let passwordLength = 10;
                  let newPassword = "";
                  for (let i = 0; i <= passwordLength; i++) {
                    let randomNumber = Math.floor(Math.random() * chars.length);
                    newPassword += chars.substring(
                      randomNumber,
                      randomNumber + 1
                    );
                  }
                  setPassword(newPassword);
                  setResetPassword(true);
                }}
              >
                Reset password
              </Button>
            </Form.Item>
          )}
        </Fragment>
      ) : (
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: "Please enter password" }]}
        >
          <Input.Password />
        </Form.Item>
      )}
      <Divider style={{ borderTop: "1px solid #ccc5b9" }} />
      <Form.Item>
        <Button type="primary" htmlType="submit" className="_xbutton">
          Save
        </Button>
        {close && (
          <Button
            type="primary"
            className="_xbutton _xbutton_dark"
            style={{ marginLeft: "15px" }}
            onClick={() => close()}
          >
            Close
          </Button>
        )}
      </Form.Item>
    </Form>
  );
}

export default UserForm;
