import React from "react";
import { observer } from "mobx-react";
import lang from "../store/lang";

const switchLangs = ["ru", "en"];

const Lang = observer(() => {
  return (
    <div className="_switch_lang">
      <img
        src={require(`../img/${lang.value}.png`)}
        alt=""
        onClick={() =>
          lang.setLang(switchLangs.filter((x) => x != lang.value)[0])
        }
      />
    </div>
  );
});

export default Lang;

/*
import React from "react";
import { observer } from "mobx-react";
import lang from "../store/lang";

const switchLangs = ["ru", "en"];

const Lang = observer(() => {
  return (
    <div className="_switch_lang">
      <img
        src={require(`../img/${lang.value}.png`)}
        alt=""
        onClick={() =>
          lang.setLang(switchLangs.filter((x) => x != lang.value)[0])
        }
      />
    </div>
  );
});

export default Lang;
*/
