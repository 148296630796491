import React, { Fragment, useEffect, useState } from "react";
import { message } from "antd";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import auth from "../store/auth";
import axios from "axios";
import ServiceForm from "../components/ServiceForm";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const Service = observer(() => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/services/${id}`)
      .then((res) => setData(res.data));
  }, []);
  return (
    <Fragment>
      <h3 onClick={() => console.log(data)}>Service page</h3>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas illo
        unde fuga ab ullam eum nemo dolorem non, debitis magni error qui magnam
        corrupti earum laboriosam incidunt animi odio a exercitationem suscipit,
        distinctio repudiandae maxime!
      </p>
      {data && (
        <ServiceForm
          data={data}
          callBack={(args) => {
            axios
              .patch(`${REACT_APP_API_PORT}api/services/${id}`, args, {
                headers: {
                  "Content-Type": "application/json",
                  "x-auth-token": auth.token,
                },
              })
              .then((res) => {
                console.log(res.data);
                message.success("Document updated suscessfully");
              })
              .catch((err) => {
                console.log(err);
                // logout
                // auth.logout();
              });
          }}
        />
      )}
    </Fragment>
  )
})

export default Service